<template>
  <div>
    <flash-message transitionIn="animated swing" class="alert-system" />

    <nav
      class="navbar fixed-top"
      style="
        max-height: 63px !important;
        background-color: #d71a20;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div
        class="text-center mx-auto"
        style="position: absolute; left: 50%; transform: translateX(-50%)"
      >
        <img
          src="/img/cartao-renner.png"
          alt=""
          class=""
          style="width: 200px"
        />
      </div>

      <ul class="navbar-nav ml-auto text-secondary" style="margin-right: 15px">
        <li
          class="nav-item dropdown no-arrow text-secondary"
          v-if="isLoggedIn && !loading"
        >
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="mr-2 small text-white">{{
              hasSpace(user.FullName)
                ? user.FullName.split(" ")[0]
                : user.FullName
            }}</span>
            <i class="fa fa-bars fa-fw fa-lg text-white"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
            style="margin-right: -20px"
          >
            <a
              class="dropdown-item"
              href="#"
              data-toggle="modal"
              data-target="#logout"
              @click="logout()"
              style="margin-right: -20px"
            >
              <i class="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>
              Encerrar
            </a>
          </div>
        </li>
      </ul>
    </nav>

    <div
      v-if="loading"
      class="container"
      style="
        min-width: 100%;
        min-height: 100vh;
        padding-bottom: 6%;
        background-color: #f1f2f2 !important;
        background-size: cover;
        background-position: right bottom;
        text-align: center;
        margin-top: 50px;
      "
    >
      <div class="container" v-if="loading">
        <ProgressLoading v-bind:loading="loading" />
      </div>
    </div>

    <div
      v-if="!isLoggedIn && !loading"
      class="container"
      style="
        min-width: 100%;
        min-height: 100vh;
        padding-bottom: 6%;
        background-color: #f1f2f2 !important;
        background-size: cover;
        background-position: right bottom;
        text-align: center;
      "
    >
      <div class="container mt-4" v-if="!isLoggedIn && !loading">
        <h4
          class="text-body mb-1 mt-5"
          style="color: #d71a20 !important; font-weight: bold"
        >
          {{ notLoggedText }}
        </h4>
        <hr />
        <div
          class=""
          v-if="notLoggedText === 'Atendimento encerrado. Obrigado!'"
        >
          <h5>
            Em caso de dúvidas acesse nossos <br />
            canais de atendimento
          </h5>
          <br />
          <h4 style="color: #d71a20 !important">3004 5060</h4>
          <h5>
            <a
              href="https://www.realizesolucoesfinanceiras.com.br/cartoes-renner/login"
              target="_blank"
              rel="noopener noreferrer"
              style="color: #d71a20"
            >
              www.realizesolucoesfinanceiras.com.br
            </a>
          </h5>
          <br />
          <div class="text-center mt-4">
            <img src="/img/cards.png" alt="" class="" style="width: 200px" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isLoggedIn && !loading"
      class="container"
      style="
        min-width: 100%;
        min-height: 100vh;
        padding-bottom: 6%;
        background-color: #f1f2f2 !important;
        background-size: cover;
        background-position: right bottom;
        margin-top: 60px;
        /* text-align: center; */
      "
    >
      <div class="container mb-3">
        <div class="row" v-if="agreement === null">
          <div
            v-bind:class="[
              largura < 500 ? 'col-12 col-lg-12 p-3' : 'col-12 col-lg-12 p-0',
            ]"
          >
            <div class="col-12 text-center">
              <h5 class="text-wrapper-2 m-0">
                Olá,
                {{
                  hasSpace(user.FullName)
                    ? user.FullName.split(" ")[0]
                    : user.FullName
                }}
              </h5>
            </div>
            <div class="d-flex">
              <div class="col-6">
                <p class="p-0 m-0" style="font-weight: bold">CPF:</p>
              </div>
              <div class="col-6 text-right">
                <p class="p-0 m-0">
                  {{
                    formatDocument(user.DocNumber)
                      .split(".")[0]
                      .substring(0, 6) +
                    ".XXX.XXX-" +
                    formatDocument(user.DocNumber).split("-")[1]
                  }}
                </p>
              </div>
            </div>
            <div class="d-flex">
              <div class="col-6">
                <p class="p-0 m-0" style="font-weight: bold">
                  Valor em Aberto:
                </p>
              </div>
              <div class="col-6 text-right">
                <p class="p-0 m-0">R${{ updatedValue }}</p>
              </div>
            </div>
            <h6 class="text-info mb-3 pt-3 text-center">
              Regularize seu Débito com facilidade e segurança, escolha uma das
              opções abaixo
            </h6>
            <br />
          </div>
        </div>
        <div
          v-bind:class="[largura < 500 ? '' : 'row']"
          v-if="mainOffersFatura.length > 0 && agreement === null"
        >
          <div
            v-bind:class="[
              largura < 500
                ? 'd-inline-flex flex-wrap flex-column col-acao my-0 pt-0 col-lg-9'
                : 'd-inline-flex flex-wrap flex-column col-acao my-0 pt-0 col-12 col-sm-12 col-md-4 col-lg-4',
            ]"
            v-for="(value, key2, index2) in mainOffersFatura"
            :key="`${key2}-${index2}`"
          >
            <div class="text-center">
              <button
                class="btn btn-primary"
                :disabled="loading || loadingDueDate"
                data-toggle="modal"
                data-target="#confirmAgreementModal"
                @click="prepareAgreement(value)"
                style="
                  border-color: #d71a20;
                  background-color: #d71a20;
                  width: 100%;
                  height: 48px;
                  border-radius: 15px;
                "
              >
                {{ value.name }}
                R${{ formatFloatToReal(value.installmentValue, 10) }}
              </button>
            </div>
          </div>
          <div
            v-bind:class="[
              largura < 500 ? 'col-lg-6 text-center' : 'col-lg-12 text-center',
            ]"
          >
            <p class="text-info mb-3 text-center">
              Vencimento à vista ou primeira parcela
            </p>
            <select
              id="Parcelas"
              v-model="dueDate"
              @change="getDueDateValue($event.target.value)"
              :style="
                largura < 500
                  ? 'width: 50% !important'
                  : 'width: 30% !important'
              "
            >
              <option
                v-for="value in dueDates"
                :key="`duedate-${value}`"
                :value="value"
                class="text-center"
              >
                {{ formatData(value, "YYYY-MM-DD", "L") }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-bind:class="[largura < 500 ? '' : 'row']"
          v-if="mainOffersCarne.length > 0 && agreement === null"
        >
          <div
            v-bind:class="[
              largura < 500
                ? 'd-inline-flex flex-wrap flex-column col-acao my-0 pt-0 col-lg-9'
                : 'd-inline-flex flex-wrap flex-column col-acao my-0 pt-0 col-12 col-sm-12 col-md-4 col-lg-4',
            ]"
            v-for="(value, key2, index2) in mainOffersCarne"
            :key="`${key2}-${index2}`"
          >
            <div class="text-center">
              <button
                class="btn btn-primary"
                :disabled="loading || loadingDueDate"
                data-toggle="modal"
                data-target="#confirmAgreementModal"
                @click="prepareAgreement(value)"
                style="
                  border-color: #d71a20;
                  background-color: #d71a20;
                  width: 100%;
                  height: 48px;
                  border-radius: 15px;
                "
              >
                {{ value.name }}
                R${{ formatFloatToReal(value.installmentValue, 10) }}
              </button>
            </div>
          </div>
          <div
            v-bind:class="[
              largura < 500 ? 'col-lg-6 text-center' : 'col-lg-12 text-center',
            ]"
          >
            <p class="text-info mb-3 text-center">
              Vencimento à vista ou primeira parcela
            </p>
            <select
              id="Parcelas"
              v-model="dueDate"
              @change="getDueDateValue($event.target.value)"
              :style="
                largura < 500
                  ? 'width: 50% !important'
                  : 'width: 30% !important'
              "
            >
              <option
                v-for="value in dueDates"
                :key="`duedate-${value}`"
                :value="value"
                class="text-center"
              >
                {{ formatData(value, "YYYY-MM-DD", "L") }}
              </option>
            </select>
          </div>
        </div>
        <div class="button-group mt-5">
          <a class="btn btn-primary" href="https://api.whatsapp.com/send/?phone=555139215464&text=Ol%C3%A1%2C+Tudo+bem%3F&type=phone_number&app_absent=0" target="_blank"
          :style="largura > 500 ? 'width: 30%;':''">
            <i class="fab fa-whatsapp"></i>
            <span>WhatsApp</span>
          </a>

          <a class="btn btn-primary" href="https://lojasrenner.onelink.me/UVlR/e7e46298" target="_blank"
          :style="largura > 500 ? 'width: 30%;':''">
            <i class="fas fa-mobile-alt"></i> 
            <span>App - Lojas Renner</span>
          </a>

          <a class="btn btn-primary" href="https://www.realizesolucoesfinanceiras.com.br/cartoes-renner/login" target="_blank"
          :style="largura > 500 ? 'width: 30%;':''">
            <i class="fas fa-globe"></i>
            <span>Site - Cartões Renner</span>
          </a>

          <a class="btn btn-primary" href="tel:30045060"
          :style="largura > 500 ? 'width: 30%;':''">
            <i class="fas fa-phone-alt"></i>
            <span>Central de Atendimento</span>
          </a>
        </div>

        <div
          class="row"
          v-if="agreement !== null"
          :style="largura < 500 ? 'margin: 16px auto 0 !important' : ''"
        >
          <div
            v-bind:class="[
              largura < 500
                ? 'd-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-9'
                : 'd-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-12 col-sm-12 col-md-12 col-lg-12',
            ]"
          >
            <div class="text-center">
              <h4
                class=""
                style="margin: auto; color: #D71A20; font-weight: bold;'"
              >
                Parabéns!
              </h4>
              <h5 class="text-body my-3" style="font-size: 12pt">
                Você receberá seu boleto por SMS e no E-mail que está cadastrado
              </h5>
              <!-- <div class="text-wrapper-10 m-3">Seu acordo ficou assim:</div> -->
              <div
                class="selected-option"
                style="background-color: #ffffff; width: 50% important"
                :style="
                  largura < 500
                    ? 'margin: auto; width: 50%;'
                    : 'max-width: 50%;'
                "
                v-if="
                  largura < 500 &&
                  parseInt(prepareToSetAgreement.installmentNumber) < 1
                "
              >
                <p class="text-wrapper-4">À vista por:</p>
                <p class="text-wrapper-5">
                  &nbsp;R${{ prepareToSetAgreement.installmentValueFormatted }}
                </p>
              </div>
              <div
                class="selected-option mt-3"
                style="width: 50% !important"
                v-if="
                  parseInt(prepareToSetAgreement.paymentInstallmentsQuantity) <
                  1
                "
              >
                À vista por&nbsp;
                <span class="price"
                  >R${{ prepareToSetAgreement.installmentValue }}</span
                >
              </div>
              <div
                v-if="
                  parseInt(prepareToSetAgreement.paymentInstallmentsQuantity) >=
                  1
                "
                class="text-center"
              >
                <div
                  class="selected-option mt-3"
                  style="margin: auto"
                  :style="largura < 500 ? 'width: 100%;' : 'width: 50%;'"
                >
                  <span v-if="prepareToSetAgreement.entryValue > 0"
                    >Entrada de R$
                    {{ prepareToSetAgreement.minimumEntryValueFormatted }}
                    <br />
                    +
                    {{ prepareToSetAgreement.paymentInstallmentsQuantity }}x de
                    <span class="price"
                      >R${{ prepareToSetAgreement.installmentValue }}
                    </span>
                  </span>
                  <span v-if="!prepareToSetAgreement.entryValue > 0">
                    {{ prepareToSetAgreement.paymentInstallmentsQuantity }}x de
                    <span class="price"
                      >R${{ prepareToSetAgreement.installmentValue }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="vencimento-do-vista mt-2">
                <span class="text-info mb-3">
                  Vencimento:
                  <strong>
                    <span
                      class="text-info mb-3"
                      style="color: #d71a20 !important"
                    >
                      {{ formatData(dueDatePlusSix, "YYYY-MM-DD", "L") }}
                    </span>
                  </strong>
                </span>
              </div>
              <div class="text-center mt-4">
                <img
                  src="/img/cards.png"
                  alt=""
                  class=""
                  style="width: 200px"
                />
              </div>
              <div class="text-center col-md-12 mt-4">
                <a
                  class=""
                  @click="logout()"
                  :disabled="loading || loadingDueDate"
                  style="
                    color: red;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  Encerrar Atendimento
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="offersFatura.length > 0 && agreement === null">
          <div
            class="row border-top border-bottom my-3 mx-auto border-info py-4"
          >
            <div v-bind:class="[largura < 500 ? 'col-lg-4' : 'col-lg-4 my-3']">
              <h5 v-bind:class="[largura < 500 ? 'text-start' : 'mt-1']">
                Selecionar outras opções:
              </h5>
            </div>
            <div v-bind:class="[largura < 500 ? 'col-lg-6' : 'col-lg-5']">
              <div>
                <select
                  id="Parcelas
            "
                  @change="getValueFatura($event.target.value)"
                >
                  <option
                    v-for="(value, key, index) in offersFatura"
                    :key="`${key}-${index}`"
                    :value="value.paymentInstallmentsQuantity"
                  >
                    <!-- {{
                      value.entryValue > 0
                        ? "Entrada de R$ " + value.entryValueFormatted + " +"
                        : ""
                    }} -->
                    {{
                      value.paymentInstallmentsQuantity === 0
                        ? "À vista "
                        : value.paymentInstallmentsQuantity + "x de"
                    }}
                    R${{ formatFloatToReal(value.installmentValue, 10) }}
                  </option>
                  <option selected>
                    Selecione uma opção
                    <i class="fa-solid fa-caret-down"></i>
                  </option>
                </select>
              </div>
            </div>
            <div
              v-bind:class="[
                largura < 500
                  ? 'text-center col-lg-2'
                  : 'my-2 text-center col-lg-3',
              ]"
            >
              <button
                class="btn btn-primary"
                :disabled="
                  loading ||
                  selectedOffer === null ||
                  typeof selectedOffer === 'undefined'
                "
                @click="prepareAgreement(selectedOffer)"
                data-toggle="modal"
                data-target="#confirmAgreementModal"
                style="
                  border-color: #d71a20;
                  background-color: #d71a20;
                  width: 100%;
                  height: 48px;
                  border-radius: 15px;
                  cursor: pointer;
                "
              >
                Confirmar acordo parcelado
              </button>
            </div>
          </div>
          <div class="container" v-if="loading">
            <ProgressLoading v-bind:loading="loading" />
          </div>
        </div>

        <div v-if="offersCarne.length > 0 && agreement === null">
          <div
            class="row border-top border-bottom my-3 mx-auto border-info py-4"
          >
            <div v-bind:class="[largura < 500 ? 'col-lg-4' : 'col-lg-4 my-3']">
              <h5 v-bind:class="[largura < 500 ? 'text-start' : 'mt-1']">
                Selecionar outras opções:
              </h5>
            </div>
            <div v-bind:class="[largura < 500 ? 'col-lg-6' : 'col-lg-5']">
              <div>
                <select
                  id="Parcelas
            "
                  @change="getValueCarne($event.target.value)"
                >
                  <option
                    v-for="(value1, key1, index1) in offersCarne"
                    :key="`${key1}-${index1}`"
                    :value="value1.paymentInstallmentsQuantity"
                  >
                    <!-- {{
                      value.entryValue > 0
                        ? "Entrada de R$ " + value.entryValueFormatted + " +"
                        : ""
                    }} -->
                    {{
                      value1.paymentInstallmentsQuantity === 0
                        ? "À vista "
                        : value1.paymentInstallmentsQuantity + "x de"
                    }}
                    R${{ formatFloatToReal(value1.installmentValue, 10) }}
                  </option>
                  <option selected>
                    Selecione uma opção
                    <i class="fa-solid fa-caret-down"></i>
                  </option>
                </select>
              </div>
            </div>
            <div
              v-bind:class="[
                largura < 500
                  ? 'text-center col-lg-2'
                  : 'my-2 text-center col-lg-3',
              ]"
            >
              <button
                class="btn btn-primary"
                :disabled="
                  loading ||
                  (!loading && selectedOffer === null) ||
                  typeof selectedOffer === 'undefined' ||
                  !selectedOffer
                "
                @click="prepareAgreement(selectedOffer)"
                data-toggle="modal"
                data-target="#confirmAgreementModal"
                style="
                  border-color: #d71a20;
                  background-color: #d71a20;
                  width: 100%;
                  height: 48px;
                  border-radius: 15px;
                  cursor: pointer;
                "
              >
                Confirmar acordo parcelado
              </button>
            </div>
          </div>
          <div class="container" v-if="loading">
            <ProgressLoading v-bind:loading="loading" />
          </div>
        </div>
        <div class="text-center mt-4" v-if="agreement === null">
          <img src="/img/cards.png" alt="" class="" style="width: 200px" />
        </div>
        <div
          class="row d-flex justify-content-center mt-3"
          v-if="agreement === null"
        >
          <div class="text-center col-md-12">
            <a
              class=""
              @click="logout()"
              :disabled="loading || loadingDueDate"
              style="color: red; text-decoration: underline; cursor: pointer"
            >
              Encerrar Atendimento
            </a>
          </div>
        </div>
      </div>

      <!-- v-else -->
      <div class="container mb-3" hidden>
        <div class="row">
          <div
            v-bind:class="[
              largura < 500 ? 'col-12 col-lg-12 p-3' : 'col-12 col-lg-12 p-0',
            ]"
          >
            <div class="text-center">
              <img src="/img/logo.png" style="width: 150px" alt="" />
            </div>
            <br />
            <br />
            <div class="row align-items-center">
              <div class="col-6">
                <h5 class="text-wrapper-2 m-0">
                  Olá,
                  {{
                    hasSpace(user.FullName)
                      ? user.FullName.split(" ")[0]
                      : user.FullName
                  }}!
                </h5>
              </div>
              <div class="col-6 text-right">
                <p class="text-info mb-0" style="font-style: italic">
                  CPF
                  {{
                    formatDocument(user.DocNumber)
                      .split(".")[0]
                      .substring(0, 6) +
                    ".XXX.XXX-" +
                    formatDocument(user.DocNumber).split("-")[1]
                  }}
                </p>
              </div>
            </div>
            <h6
              class="text-info mb-3 pt-5 text-start"
              style="font-style: italic; height: 40vh"
              v-if="contractsWithAgreement.length > 1"
            >
              Você já possui um acordo em andamento para o(s) contrato(s):
              {{ contractsWithAgreement.join(", ") }}
            </h6>
            <h6
              class="text-info mb-3 pt-5 text-start"
              style="font-style: italic; height: 40vh"
              v-if="contractsWithAgreement.length <= 1"
            >
              Você já possui um acordo em andamento para o contrato:
              {{ contractsWithAgreement.join(", ") }}
            </h6>
            <div class="row d-flex justify-content-center mt-3">
              <div class="text-center col-md-12">
                <a
                  class=""
                  @click="logout()"
                  :disabled="loading || loadingDueDate"
                  style="
                    color: red;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  Encerrar Atendimento
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="confirmAgreementModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmAgreementModalLabel"
      aria-hidden="true"
      style="background-color: #d71a20"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div
          class="modal-content"
          :style="
            largura < 500
              ? 'margin: 30px !important;'
              : 'margin: 90px !important'
          "
        >
          <div class="modal-header">
            <h5 class="modal-title" id="confirmAgreementModalLabel">
              Confirmar acordo
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="prepareToSetAgreement !== null">
            <p class="option-label">Opção escolhida:</p>
            <div
              class="selected-option mt-3"
              v-if="
                parseInt(prepareToSetAgreement.paymentInstallmentsQuantity) < 1
              "
            >
              À vista por&nbsp;
              <span class="price"
                >R${{ prepareToSetAgreement.installmentValue }}</span
              >
            </div>
            <div
              v-if="
                parseInt(prepareToSetAgreement.paymentInstallmentsQuantity) >= 1
              "
            >
              <div class="selected-option mt-3 text-center">
                <span v-if="prepareToSetAgreement.entryValue > 0"
                  >Entrada de R$
                  {{ prepareToSetAgreement.minimumEntryValueFormatted }}
                  <br />
                  +
                  {{ prepareToSetAgreement.paymentInstallmentsQuantity }}x de
                  <span class="price"
                    >R${{ prepareToSetAgreement.installmentValue }}
                  </span>
                </span>
                <span v-if="!prepareToSetAgreement.entryValue > 0">
                  {{ prepareToSetAgreement.paymentInstallmentsQuantity }}x de
                  <span class="price"
                    >R${{ prepareToSetAgreement.installmentValue }}
                  </span>
                </span>
              </div>
            </div>
            <div class="mt-3">
              <ProgressLoading v-bind:loading="loadingSetAgreement" />
            </div>
            <button
              class="btn-primary btn-block text-white mt-4"
              style="
                cursor: pointer;
                border-radius: 0.9rem;
                border-color: #d71a20;
                background-color: #d71a20;
                min-height: 40px;
              "
              @click="setAgreement()"
              v-if="!loadingSetAgreement"
            >
              CONFIRMAR ACORDO
            </button>
            <button
              class="btn-link btn-block"
              data-dismiss="modal"
              style="cursor: pointer; color: #d71a20"
              v-if="!loadingSetAgreement"
            >
              CANCELAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatData,
  formatDocument,
  addDaysAndFormatData,
  ActionGetIPAddress,
  datePlusSixDaysFormated,
  formatDate,
  getEaster,
  getBrazilianHolidays,
  isBusinessDay,
} from "@/utils/helpers";
import { mask } from "vue-the-mask";
import ProgressLoading from "@/modules/auth/components/ProgressLoading";
import moment from "moment";

export default {
  name: "Home",
  components: {
    ProgressLoading,
  },
  directives: { mask },
  data: () => ({
    hrefwhatsapp: "https://api.whatsapp.com/send?l=pt&phone=551132303765",
    largura: null,
    pageName: "",
    ipUser: null,
    isLogged: false,
    offers: [],
    mainOffers: [],
    selectedDebt: null,
    paymentType: "",
    toggle: true,
    loading: false,
    notLoggedText: "Por favor, use um link de acesso válido.",
    selectedOffer: null,
    docNumber: null,
    clientId: null,
    agreement: null,
    newEmail: null,
    newPhoneNumber: null,
    updatedValue: null,
    dueDate: null,
    debtorId: null,
    finalized: false,
    lateDate: "",
    dueDates: [],
    loadingDueDate: false,
    prepareToSetAgreement: null,
    loadingSetAgreement: false,
    dueDatePlusSix: null,
    contract: null,
    hasAgreement: false,
    contractsWithAgreement: [],
    mainOffersFatura: [],
    mainOffersCarne: [],
    offersFatura: [],
    offersCarne: [],
    product: null,
    onlyOffers: false,
  }),
  methods: {
    ...mapActions("auth", [
      "ActionGetLinkDirectAccess",
      "ActionDoLogout",
      "ActionContatoWhatsapp",
    ]),
    ...mapActions("meusContratos", [
      "ActionGetDebtsRenner",
      "ActionSetAgreementBoletoRenner",
      "ActionSendAgreementEmailOrWhatsappRenner",
    ]),
    ...mapActions("meusBoletos", ["ActionSendEmailOrSmsText"]),

    formatDocument,
    formatData,
    addDaysAndFormatData,
    datePlusSixDaysFormated,
    formatDate,
    getEaster,
    getBrazilianHolidays,
    isBusinessDay,

    selectDebt() {
      this.selectedDebt = this.offers[0];
    },

    selectPaymentType(value, type) {
      this.paymentType = type;
    },

    formatFloatToReal(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },

    changeToggle() {
      this.toggle = !this.toggle;
    },

    clickWhatsapp() {
      this.ActionContatoWhatsapp({
        DocNumber: this.docNumber ?? null,
        tag: localStorage.getItem("tag"),
        IP: this.ipUser ? this.ipUser : "",
        DebtorID: this.debtorId ?? null,
      });
    },

    async logout() {
      // this.isLogged = false;
      // this.user = null;
      this.notLoggedText = "Atendimento encerrado. Obrigado!";
      this.finalized = true;
      await this.ActionDoLogout();
    },

    getValueFatura(value) {
      const offer = this.offersFatura.filter(
        (off) => off.paymentInstallmentsQuantity == value
      )[0];
      this.selectedOffer = offer;
    },

    getValueCarne(value) {
      const offer = this.offersCarne.filter(
        (off) => off.paymentInstallmentsQuantity == value
      )[0];
      this.selectedOffer = offer;
    },

    async getDueDateValue(value) {
      this.loadingDueDate = true;

      this.dueDate = value;

      this.loadingDueDate = false;
    },

    async sendWhatsapp() {
      this.sendEmailOrWhatsapp({ sendEmail: false, sendWhatsapp: true });
      this.logout();
    },

    async buildOffersWithoutDebts(result) {
      // eslint-disable-next-line
      // debugger;
      this.updatedValue = result.offers[0].installmentValue;
      this.offers = result.offers;
      this.product = result.debts.produto[0];

      this.dueDatePlusSix = datePlusSixDaysFormated();

      this.lateDate = moment(result.earliestDueDate).format("DD/MM/YYYY");

      this.populateDatesWithoutDebts();

      switch (this.product) {
        case "CBR":
          await this.isFatura(result);
          break;
        case "CCR":
          await this.isCarne(result);
          break;
        default:
          break;
      }

      this.loading = false;
    },

    async getDebts() {
      try {
        const result = await this.ActionGetDebtsRenner({
          IP: this.ipUser ? this.ipUser : "",
          DeviceName: process.env.VUE_APP_API_DEVICE,
          OS: navigator.platform,
          UserFullName: this.UserFullName,
          DocNumber: this.docNumber,
          tag: localStorage.getItem("tag"),
          UserID: this.userAdmId ? this.userAdmId : null,
        });

        if (result.offers.length === 0) {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            title: "Erro",
            message: "Nenhuma oferta encontrada.",
          });

          this.notLoggedText = "Nenhuma oferta encontrada.";
          await this.ActionDoLogout();

          this.loading = false;
          return;
        }

        if (result.withoutDebtsInfo) {
          this.onlyOffers = true;
          this.buildOffersWithoutDebts(result);
          return;
        }

        if (this.dueDates.length === 0) {
          result.parametrosOpcoesPagamento.datasEntrada.map((value) => {
            this.dueDates.push(value);
          });

          this.dueDate = this.dueDates[0];
        }

        // this.populateDates(result);

        this.updatedValue = result.offers[0].installmentValue;
        this.offers = result.offers;

        this.product = result.debts.produto;

        this.dueDatePlusSix = datePlusSixDaysFormated();

        this.lateDate = moment(result.earliestDueDate).format("DD/MM/YYYY");

        switch (this.product) {
          case "CBR":
            await this.isFatura(result);
            break;
          case "CCR":
            await this.isCarne(result);
            break;
          default:
            break;
        }

        this.loading = false;
      } catch (err) {
        this.flashMessage.show({
          timeout: 300,
          status: "error",
          position: "top right",
          title: "Erro",
          message: "Nenhuma oferta encontrada.",
        });

        this.notLoggedText = "Nenhuma oferta encontrada.";
        await this.ActionDoLogout();

        this.loading = false;
      }
    },

    async isFatura(result) {
      const today = new Date();

      const filteredOffers = result.offers
        .filter((offer) => {
          const dueDate = new Date(offer.dueDate);
          return dueDate < today;
        })
        .map((offer) => ({
          ...offer,
          name:
            offer.paymentInstallmentsQuantity === 0 ||
            offer.paymentInstallmentsQuantity === 1
              ? "À vista"
              : `${offer.paymentInstallmentsQuantity}x de ${offer.installmentValue}`,
        }));

      if (filteredOffers.length > 0) {
        filteredOffers.sort(
          (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
        );
        this.mainOffersFatura = [filteredOffers[0]];
      } else {
        this.mainOffersFatura =
          result.offers.length > 0
            ? [
                {
                  ...result.offers[0],
                  name:
                    result.offers[0].paymentInstallmentsQuantity === 0 ||
                    result.offers[0].paymentInstallmentsQuantity === 1
                      ? "À vista"
                      : `${result.offers[0].paymentInstallmentsQuantity}x de ${result.offers[0].installmentValue}`,
                },
              ]
            : [];
      }
    },

    async isCarne(result) {
      const today = new Date();

      const filteredOffers = result.offers
        .filter((offer) => {
          const dueDate = new Date(offer.dueDate);
          return dueDate < today;
        })
        .map((offer) => ({
          ...offer,
          name:
            offer.paymentInstallmentsQuantity === 0 ||
            offer.paymentInstallmentsQuantity === 1
              ? "À vista"
              : `${offer.paymentInstallmentsQuantity}x de ${offer.installmentValue}`,
        }));

      if (filteredOffers.length > 0) {
        filteredOffers.sort(
          (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
        );
        this.mainOffersCarne = [filteredOffers[0]];
      } else {
        this.mainOffersCarne =
          result.offers.length > 0
            ? [
                {
                  ...result.offers[0],
                  name:
                    result.offers[0].paymentInstallmentsQuantity === 0 ||
                    result.offers[0].paymentInstallmentsQuantity === 1
                      ? "À vista"
                      : `${result.offers[0].paymentInstallmentsQuantity}x de ${result.offers[0].installmentValue}`,
                },
              ]
            : [];
      }
    },

    async verifyIfIsDirectAccessLink(encryptedData, queryType) {
      this.loading = true;

      try {
        const response = await this.ActionGetLinkDirectAccess({
          encryptedData: encryptedData,
          queryType: queryType,
          IP: this.ipUser ? this.ipUser : "",
          DeviceName: process.env.VUE_APP_API_DEVICE,
          OS: navigator.platform,
          UserFullName: this.UserFullName,
          tag: localStorage.getItem("tag"),
        });

        this.UserFullName = response.tag;
        this.docNumber = response.docNumber;
        this.debtorId = response.debtorId;
        this.userAdmId = response.userAdmId;
        this.fullName = response.FullName;

        if (response.success) {
          if (response.userSiscobra) {
            this.loading = false;

            return this.$router.push({
              name: "siscobra",
              params: {
                DebtorID: this.debtorId,
                UserID: this.userAdmId,
                FullName: this.fullName,
              },
            });
          } else {
            const debts = await this.getDebts();

            if (!this.dueDate) {
              this.dueDate = debts?.length
                ? moment(debts.offers[0].minDueDate, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                : moment().format("DD/MM/YYYY");
            }
          }
        } else {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            title: "Erro",
            message: response.message,
          });
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },

    async setAgreement() {
      this.loadingSetAgreement = true;

      try {
        const result = await this.ActionSetAgreementBoletoRenner({
          IP: this.ipUser ? this.ipUser : "",
          OS: navigator.platform,
          DeviceName: process.env.VUE_APP_API_DEVICE,
          UserFullName: this.UserFullName,
          DocNumber: this.docNumber,
          dueDate: this.dueDate,
          contractNumber: this.product,
          installmentNumber:
            this.prepareToSetAgreement.paymentInstallmentsQuantity,
          installmentValue: this.prepareToSetAgreement.installmentValue,
          mode: this.prepareToSetAgreement.payOptionId,
          tag: localStorage.getItem("tag"),
          UserID: this.userAdmId ? this.userAdmId : null,
        });
        this.loadingSetAgreement = false;

        if (!result.boletoLink) {
          this.flashMessage.show({
            timeout: 300,
            status: "error",
            position: "top right",
            title: "Erro",
            message: "Não foi possível fechar o acordo!",
          });
          this.loadingSetAgreement = false;
          return;
        }

        this.flashMessage.show({
          timeout: 1000,
          status: "success",
          title: "Sucesso",
          blockClass: "custom-block-class",
          message: "Acordo fechado com sucesso",
        });

        this.fecharModalConfirmarAcordo();

        const agreement = result.results;

        this.agreement = {
          ...agreement,
          dueDate: this.prepareToSetAgreement.dueDate,
        };

        this.finalized = true;

        // this.sendSmsOrEmailOrWhatsapp();
      } catch (error) {
        this.loadingSetAgreement = false;
      }
    },

    fecharModalConfirmarAcordo() {
      const modal = document.getElementById("confirmAgreementModal");

      modal.classList.remove("show");
      modal.style.display = "none";

      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }

      document.body.classList.remove("modal-open");
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    },

    async getIPAddressUser() {
      let maxAttempts = 10;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          this.ipUser = await ActionGetIPAddress();
          if (this.ipUser !== null) {
            localStorage.setItem("ipUser", this.ipUser);
          }
          break;
        } catch (error) {
          console.log("Erro ao obter o endereço IP. Tentando novamente...");
          attempts++;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      if (attempts === maxAttempts) {
        console.log(
          "Número máximo de tentativas atingido. Não foi possível obter o endereço IP."
        );
      }
    },

    async sendSms(LinhaDigitavel) {
      this.loading = true;

      try {
        var resp = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel,
          Email: "",
          PhoneNumber:
            this.user.PhoneNumber != null
              ? this.user.PhoneNumber
              : this.user.MobileNumber,
          type: "sms",
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
          UserID: this.userAdmId ? this.userAdmId : null,
          tag: localStorage.getItem("tag"),
        });

        if (resp.data.code !== 200) {
          this.flashMessage.show({
            time: 3000,
            status: "error",
            title: "Error",
            message: "Não foi possível fazer o envio por sms",
          });
          this.loading = false;
          return;
        }

        this.flashMessage.show({
          timeout: 1000,
          blockClass: "custom-block-class",
          status: "success",
          title: "Sucesso",
          message: "Linha digitável enviado por sms com sucesso",
        });
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async sendEmail(LinhaDigitavel) {
      this.loading = true;

      try {
        var resp = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel,
          Email: this.user.Email,
          PhoneNumber: "",
          type: "email",
          httpOwnerId: this.ownerId,
          OS: navigator.platform,
          UserID: this.userAdmId ? this.userAdmId : null,
          tag: localStorage.getItem("tag"),
        });

        if (resp.code !== 200) {
          this.flashMessage.show({
            time: 3000,
            status: "error",
            title: "Error",
            message: "Não foi possível fazer o envio por e-mail",
          });
          this.loading = false;
          return;
        } else {
          this.loading = false;
          this.flashMessage.show({
            time: 3000,
            blockClass: "custom-block-class",
            status: "success",
            title: "Sucesso",
            message: "Linha digitável enviado por e-mail com sucesso",
          });
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async sendSmsOrEmailOrWhatsapp() {
      this.loading = true;

      try {
        let resp = await this.ActionSendAgreementEmailOrWhatsappRenner({
          clientName: this.user.FullName,
          document: this.user.DocNumber,
          contractNumber: this.product,
          date: this.dueDate,
          mode: this.prepareToSetAgreement.payOptionId,
          phoneNumber: this.user.PhoneNumber ?? "",
          sendWhats: this.user.PhoneNumber ?? "",
          sendEmail: this.user.Email ?? "",
          OS: navigator.platform,
          UserID: this.userAdmId ? this.userAdmId : null,
          tag: localStorage.getItem("tag"),
        });

        if (resp.notSend) {
          this.flashMessage.show({
            time: 3000,
            status: "error",
            title: "Erro",
            message: `Não foi possível fazer o envio do boleto.`,
          });
          this.loading = false;
          return;
        } else {
          this.loading = false;
          this.flashMessage.show({
            time: 3000,
            blockClass: "custom-block-class",
            status: "success",
            title: "Sucesso",
            message: `Boleto enviado com sucesso`,
          });
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    formatarCnpjCpf(cnpjCpf) {
      const numeros = cnpjCpf.replace(/\D/g, "");
      if (numeros.length === 11) {
        return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else if (numeros.length === 14) {
        return numeros.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      } else {
        return cnpjCpf;
      }
    },
    prepareAgreement(value) {
      this.prepareToSetAgreement = value;
    },
    hasSpace(str) {
      return /\s/.test(str);
    },
    populateDates(debts) {
      // eslint-disable-next-line
      // debugger;
      const now = moment(this.dueDate, "DD/MM/YYYY");
      var dates = debts.parametrosOpcoesPagamento.datasEntrada;
      const maximumDate = moment(dates[dates.length - 1], "DD/MM/YYYY");

      const holidays = getBrazilianHolidays(now.year());
      let count = 0;

      while (count < 6) {
        if (now.isAfter(maximumDate)) {
          break;
        }

        if (isBusinessDay(now, holidays)) {
          this.dueDates.push(now.format("DD/MM/YYYY"));
          count++;
        }

        now.add(1, "day");
      }
    },
    populateDatesWithoutDebts() {

      const now = moment(this.dueDate == null ? this.lateDate : this.dueDate, "DD/MM/YYYY");

      const maximumDate = moment(now).clone().add(10, "days"); // Clone para não alterar "now"

      const holidays = getBrazilianHolidays(now.year());
      let count = 0;

      while (count < 6) {
        if (now.isAfter(maximumDate)) {
          break;
        }

        if (isBusinessDay(now, holidays)) {
          this.dueDates.push(now.format("YYYY/MM/DD"));
          count++;
        }

        now.add(1, "days"); // Adiciona dias, não anos
      }
      this.dueDate = this.dueDates[0];
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  async created() {
    this.largura = window.screen.width;
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.loading = true;
    await this.getIPAddressUser();

    if (this.user) {
      this.docNumber = this.user.DocNumber;
      this.phoneNumber = this.user.PhoneNumber;
      this.debtorId = this.user.DebtorID;

      const debts = await this.getDebts();
      this.debts = debts;

      return;
    }

    if (this.$route.query.codePass) {
      const encryptedData = this.$route.query.codePass.replaceAll(" ", "+");
      this.verifyIfIsDirectAccessLink(encryptedData, "codePass");
    } else if (this.$route.query.cod) {
      var infoEncrypted = this.$route.query.cod;
      if (infoEncrypted !== null) {
        localStorage.setItem("infoEncrypted", infoEncrypted);
        localStorage.removeItem("encryptedData");
        this.verifyIfIsDirectAccessLink(infoEncrypted, "cod");
      } else {
        this.flashMessage.show({
          timeout: 300,
          status: "error",
          position: "top right",
          title: "Erro",
          message: "Ocorreu um erro.",
        });
        return;
      }
    } else {
      this.loading = false;
    }
  },
};
</script>
<style>
.card-height {
  min-height: 280px !important;
}

.card-body-flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

select {
  width: 100%;
  padding: 10px;
  margin: 10px 0 5px 0;
  border-radius: 20px;
  border: 1px solid #d71a20;
  background-color: #fff;
  color: #d71a20;
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  outline: none;
}

select:focus {
  border-color: #d71a20;
  box-shadow: 0 0 5px rgba(0, 63, 141, 0.5);
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  background-color: #d71a20;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 95%;
  text-align: center;
}

.btn i {
  margin-right: 10px;
}

.btn:hover {
  background-color: #b8171b;
}

.btn span {
  flex: 1;
  text-align: center;
}

.nav-item.dropdown.show .dropdown-menu {
  right: 0 !important;
  left: auto !important;
  position: absolute;
}

.navbar {
  min-height: 63px; 
  display: flex;
  align-items: center; 
  justify-content: space-between;
}

.navbar img {
  max-height: 50px;
}

.navbar ul {
  display: flex;
  align-items: center;
}
</style>
